<template>
	<div class="con_box">
		<div :style="{ display: 'flex' }" ref="floorImage">
			<div>
				<div class="col-12 EHPLeft">
					<div :id="'panel'" class="tabcontent controlPanel" :class="'current'" :data-panel="'panel'">
						<div class="con_wrap" :style="{ height: '100%' }">
							<!-- Light list -->
							<light-item
								:ref="'accordian'"
								v-if="equipList && equipListDetail"
								:zoneInfo="zoneInfoList[zoneIdx]"
								:equipList="equipList"
								:equipListDetail="equipListDetail"
								:ctrPtAddr="ctrPtAddr"
								:ctrlPropList="ctrlPropList"
								:update="equipStatus"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";
	import lightItem from "./LightingItem.vue";
	import config from "@libs/config.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			lightItem,
		},
		props: ["zoneInfoList"],
		data() {
			return {
				zoneIdx: null,
				ctrlCode: "CTRL",
				equipList: null,
				equipListDetail: null,
				zoneDsnFilePath: null,
				ctrPtAddr: null,
				ctrlPropList: null,
				zoneData: {
					zoneIdx: null,
				},
				tabIndex: 0,
				equipCtrlList: null,
				zoneIdxList: null,
				xemsHost: config.backend.apiUrl,
				equipLctn: {
					top: null,
					left: null,
				},
				popupMessages,
			};
		},
		computed: {},
		watch: {
			zoneIdx() {
				this.reset();
				this.equipStatus();
			},
		},
		async created() {
			//this.zoneIdxList = await this.getZoneListHierarchi();
			this.equipCtrlList = await this.getEquipCtrlList();
		},
		mounted() {
			this.init();
		},
		methods: {
			loadOptions({ callback }) {
				callback();
			},
			init() {
				this.zoneIdx = this.zoneInfoList[0].zoneIdx;
			},
			reset() {
				this.equipList = null;
				this.zoneDsnFilePath = null;
			},
			// async getZoneListHierarchi() {

			// 	try {
			// 		let result = await backEndApi.zoneInfo.getZoneListHierarchi();
			// 		let options = [];
			// 		let temp = [];
			// 		temp.push(result.data);
			// 		options = this.setZoneTreeOption(temp);
			// 		return options;
			// 	} catch (err) {
			// 		new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
			// 	}
			// },
			async getEquipCtrlList() {
				try {
					let result = await backEndApi.equipCtrl.getEquipCtrlList("LIT");

					let options = [];
					for (let i = 0; i < result.data.length; i++) {
						let option = {
							value: result.data[i].ctrlCode,
							text: result.data[i].equipCtrlName,
							data: result.data[i],
						};

						options.push(option);
					}

					return options;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async equipStatus() {
				try {
					let result = await backEndApi.equip.equipStatus("LIT", this.zoneIdx, this.ctrlCode);

					let data = result.data;

					this.ctrPtAddr = [];
					this.equipList = data.equipList;
					this.ctrlPropList = data.ctrlPropList;

					for (let i = 0; i < data.equipList.length; i++) {
						this.ctrPtAddr.push(data.equipList[i].ctrPtAddr);
						let equip = data.equipList[i];
						let equipLctn = equip.equipLctn;

						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
					}

					this.equipListDetail = data.equipList[0].propList;
					this.equipLctn.top = JSON.parse(data.equipList[0].equipLctn).top;
					this.equipLctn.left = JSON.parse(data.equipList[0].equipLctn).left;
					this.zoneDsnFilePath = data.equipList[0].zoneDsnFilePath.replace(/\\/g, "/");

					console.log(this.equipListDetail);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			iniEquipLctn(equipLctn, index) {
				let file = new Image();

				file.src = `${this.xemsHost}${this.zoneDsnFilePath}`;

				let xValue;
				file.onload = () => {
					xValue = 1095 / file.width;
				};

				if (xValue === undefined) {
					xValue = 1;
				}

				if (equipLctn.trim() === "") {
					return JSON.stringify({ top: index * 50, left: index * 100 });
				} else {
					let splitLctn = equipLctn.split(",");
					let x = splitLctn[0];
					let y = splitLctn[1];

					if (x.trim() === "") {
						x = index * 50;
					} else {
						x = Number(splitLctn[0]) * xValue;
					}

					if (y.trim() === "") {
						y = index * 100;
					} else {
						y = Number(splitLctn[1]) * xValue;
					}

					return JSON.stringify({ top: x, left: y });
				}
			},
			async getEquipListDetail(equipIdx) {
				try {
					let result = await backEndApi.equip.equipStatus("LIT", this.zoneIdx, this.ctrlCode);

					console.log(result.data);
					this.ctrPtAddr = [];
					this.ctrlPropList = result.data.ctrlPropList;
					let equipList = result.data.equipList;
					for (let i = 0; i < equipList.length; i++) {
						this.ctrPtAddr.push(equipList[i].ctrPtAddr);
					}
					for (let i = 0; i < equipList.length; i++) {
						let equip = equipList[i];
						let equipLctn = equip.equipLctn;
						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
						if (equip.equipIdx === equipIdx) {
							this.equipListDetail = equip.propList;
							this.zoneDsnFilePath = equip.zoneDsnFilePath;

							this.equipLctn.top = JSON.parse(equip.equipLctn).top;
							this.equipLctn.left = JSON.parse(equip.equipLctn).left;
							break;
						}
					}
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},

			async ALLgetEquipListDetail(equipIdx) {
				try {
					let result = await backEndApi.equip.equipStatus("LIT", this.zoneIdx, this.ctrlCode);
					console.log(result.data);
					this.ctrPtAddr = [];
					this.ctrlPropList = result.data.ctrlPropList;
					let equipList = result.data.equipList;
					console.log(equipList);
					for (let i = 0; i < equipList.length; i++) {
						this.ctrPtAddr.push(equipList[i].ctrPtAddr);
					}

					for (let i = 0; i < equipList.length; i++) {
						let equip = equipList[i];
						let equipLctn = equip.equipLctn;
						equip.equipLctn = this.iniEquipLctn(equipLctn, i);
						console.log(equipIdx);
						if (equip.equipIdx === equipIdx) {
							this.equipListDetail = equip.propList;
							this.zoneDsnFilePath = equip.zoneDsnFilePath;

							this.equipLctn.top = JSON.parse(equip.equipLctn).top;
							this.equipLctn.left = JSON.parse(equip.equipLctn).left;
							break;
						}
					}
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
		},
	};
</script>

<style lang="scss">
	.con_box {
		.EHPLeft {
			background-color: #ffffff;
			border: 1px solid #313131;
			border-radius: 5px;
			min-width: 1638px;

			.vue-treeselect__single-value {
				padding-left: 10px;
			}

			.SearchLabel {
				font-size: 14px;
				font-weight: bold;
				align-self: center;
				text-align: center;
				padding: 0;
			}
		}

		.EHPRight {
			background-color: #ffffff;
			border: 1px solid #313131;
			border-radius: 5px;
			padding: 15px;
			min-width: 830px;
		}
	}
</style>
