<template>
  <div class="col-md-12 default" :style="{ overflow: 'hidden', minHeight: '500px' }">
    <div class="col-xl-12">
      <header-box :title="`${$t('건물 운영 관리')} > ${$t('조명 제어')}`" />
      <div class="content_custom">
        <div class="content_wrapper">
          <tab-box v-if="zoneInfoList" :zoneInfoList="zoneInfoList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "@api/backEndApi.js";
import headerBox from "@views/component/headerBox/analysis/Header.vue";
import tabBox from "./LightingTab.vue";

export default {
  components: {
    headerBox,
    tabBox,
  },
  data() {
    return {
      zoneInfoList: null,
    };
  },
  mounted() {
    this.getZoneInfoList();
  },
  methods: {
    getZoneInfoList() {
      backEndApi.zoneInfo.getZoneInfoList().then(({ data }) => {
        this.zoneInfoList = data;
      });
    },
  }
}
</script>

<style></style>